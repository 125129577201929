exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-call-with-ricky-js": () => import("./../../../src/pages/call-with-ricky.js" /* webpackChunkName: "component---src-pages-call-with-ricky-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-startups-js": () => import("./../../../src/pages/startups.js" /* webpackChunkName: "component---src-pages-startups-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

